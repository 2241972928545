import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
import $userRequest from '@/request/contactsapi.js'
import { regionData, CodeToText, TextToCode } from 'element-china-area-data' // CodeToText
const Index = {
  name: 'purchaseRequest',
  mixins: [tableMixin, root],
  components: {
    PageHeader,
    Adsearch
  },
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    },
    visible(e) {
      console.log(e)
      if (e) {
        this.$api.QueryDeliveryAddressList({
          companyID: this.form.TempBednrCompanyID ? this.form.TempBednrCompanyID : 0
        }).then(res => {
          res.Message.forEach(ele => {
            if (ele.DefaultFlag) {
              this.slectAddressDefault(ele, 10)
              return
            }
          })
        })
        this.$api.QueryCollectTicketAddressList({
          companyID: this.form.BednrCompanyID ? this.form.BednrCompanyID : 0
        }).then(res => {
          res.Message.forEach(ele => {
            if (ele.DefaultFlag) {
              this.slectAddressDefault(ele, 20)
              return
            }
          })
        })
      }
    },
    LotNoRequire(a) {
      console.log(a)
      this.form.LotNoRequire = a
      if (a) {
        let invCodes = ''
        this.tableDataP.forEach(item => {
          invCodes += `${item.InvCode},`
        })
        this.$api.GetHistoryLotNum({
          CompanyID: this.form.BednrCompanyID ? this.form.BednrCompanyID : 0,
          InvCodes: invCodes
        }).then(res => {
          console.log(res)
          res.Message.map(item => {
            this.tableDataP.forEach(item1 => {
              if (item.InvCode === item1.InvCode) {
                this.$set(item1, 'LotNum', item.LotNum)
                this.$set(item1, 'ExpiredDate', item.ExpiredDate)
              }
            })
          })
        })
        return
      }
      this.tableDataP.forEach(item => {
        this.$set(item, 'LotNum', '')
        this.$set(item, 'ExpiredDate', 0)
      })
    }
  },
  data() {
    return {
      root_name: 'cgsq',
      root_msg: '采购申请',
      isDevelopment: process.env.NODE_ENV,
      loading: false,
      visiblePRODUCT: false,
      paginationName1: 'tablePagination1',
      tableTotalSize1: 0,
      pagerCount1: 5,
      tablePageSize1: 5,
      tableBackground1: true,
      LotNoRequire: false,
      QueryType: 2, // 1:所有 2：待处理 3:部分处理 4：已处理 5：已关闭 6：我的订单
      orderNumClass: 'hederleft cursor',
      orderNumClassActive: 'orderheadactive hederleft cursor',
      ordderNumList: {
        DealNum: 12,
        DealPartNum: 312,
        OwnDealNum: 543,
        PendingNum: 63,
        ReturnNum: 31,
        Total: 123
      },
      formSearch: {
        KeyWords: '',
        Status: '',
        B2BPurchaseBasicID: '', // 采购申请编号
        OrderID: '', // 订单来源编号
        ApplyUserNickName: '', // 采购申请人
        CompanyName: '', //  订单客户
        BeginDate: '',
        EndDate: '',
        DateCA: [], // 申请日期
        NoticeUserNickName: '', // 待处理人
        PageIndex: '',
        PageSize: ''
      },
      formSearchList: {
        KeyWords: '',
        Status: '',
        SapPurchaseID: '', // 采购申请编号
        OrderID: '', // 订单来源编号
        ApplyUserNickName: '', // 采购申请人
        CompanyName: '', //  订单客户
        BeginDate: '',
        EndDate: '',
        DateCA: [], // 申请日期
        NoticeUserNickName: '', // 待处理人
        PageIndex: '',
        PageSize: ''
      },
      adSearchFormP: [
        { name: '物料编码', value: 'MaterialInvCode' },
        { name: '物料品牌', value: 'BrandName' },
        { name: '物料名称', value: 'MaterialName' },
        { name: '物料类型', value: 'MaterialType' }
      ],
      formProduct: {
        StockFiler: 1,
        KeyWords: '',
        MaterialInvCode: '', // 物料编码
        BrandName: '', // 物料品牌
        MaterialName: '', // 物料名称
        MaterialType: '', // 物料类型
        // DINum: '', // 物料DI码
        PageIndex: '',
        PageSize: ''
      },
      // formSearchList: {
      //   KeyWords: '',
      //   B2BPurchaseBasicID: '', // 采购申请编号
      //   OrderID: '', // 订单来源编号
      //   ApplyUserNickName: '', // 采购申请人
      //   CompanyName: '', //  订单客户
      //   BeginDate: '',
      //   EndDate: '',
      //   DateCA: [], // 申请日期
      //   NoticeUserNickName: '', // 待处理人
      //   PageIndex: '',
      //   PageSize: ''
      // },
      adSearchList: [
        { name: '采购申请编号', value: 'SapPurchaseID' },
        { name: '订单来源编号', value: 'OrderID' },
        { name: '采购申请人', value: 'ApplyUserNickName' },
        { name: '订单客户', value: 'CompanyName' },
        { name: '申请日期', value: 'DateCA', date: true },
        { name: '待处理人', value: 'NoticeUserNickName' }
      ],
      productShowList: false,
      isSubmit: false,
      visible1: false,
      isShow: false,
      adSearchForm: [],
      selects: [],
      visible: false,
      tableDataProduct: [],
      form: {
        ExpiredDate: '',
        ArrivalDate: '',
        LotNoRequire: '',
        isKe: 0,
        StockCode: '',
        DivisionDeptID: '',
        SendID: '',
        CollectTicketSendID: '',
        Remarks: '',
        NoticeUser: '',
        BednrCompanyID: '',
        TempBednrCompanyID: 0,
        IsTakeSelf: '',
        card: '',
        address: '',
        NickName: ''
      },
      _width: '',
      tableDataP: [],
      tableData: [],
      isAddress: false,
      addressList: [
        { name: '王老吉', status: false },
        { name: '测试地址', status: true },
        { name: '测试地址234', status: true }
      ],
      // 期效要求
      DateList: [],
      // 通知处理人
      UserList: [],
      // 入库仓位
      StockList: [],
      showSetAddress: false,
      ruleForm1: {
        IDX: '',
        TakeSelf: '', // 是否自提-0:否 1:是,
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddrType: 10 // 地址类型-10 收货地址 20 收票地址
      },
      rules1: {
        Province: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        City: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        Area: [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        ContactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        ContactTel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ]
      },
      isAddressLoading: true,
      clients: [],
      DeptNameQueryListJson: [],
      Province: [],
      City: [],
      Area: [],
      productFile: {},
      productShow: false,
      selectsP: [],
      // ================================================= 选择人员 begin  =================================================
      // ================================================= 选择人员 begin ==================================================
      seletUserVisible: false,
      isDataCome: true,
      selectUseractiveName: 'user', // 选择人员tab切换
      isshow: false, // 控制 部门和角色选择是否显示
      selectUserkeywords: '', // 搜索成员关键字
      connuserlist: [], // 成员list
      fullscreenLoading: false,
      selectUserDeptData: [], // 部门数据
      selectUserroleData: [], // 角色数据
      checkeduserIdList: [], // 选中的列表
      checkedDeptList: [], // 选中的部门
      checkedRoleList: [] // 选中的角色
      // ================================================= 选择人员 end  =================================================
      // ================================================= 选择人员 end ==================================================
    }
  },
  mounted() {
    this.QueryPurchaseApplyCount()
    regionData.map(item => {
      this.Province.push({
        label: item.label,
        value: item.value
      })
    })
    if (this.$route.params.from === 'Guidepage') {
      this.showCreateOrder()
    }
  },
  methods: {
    upExPORTfILE(file, type) {
      if (!this.productFile.FileData) {
        return this.$message.warning('请选择文件')
      }
      this.$api.CreatePurchase_Import({
        FileData: this.productFile.FileData,
        FileType: this.productFile.FileType
      }).then(res => {
        console.log(res)
        this.$refs.isRefFile.value = null
        this.productFile = {}
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        if (Array.isArray(res.Message)) {
          if (this.tableDataP.length <= 0) {
            this.tableDataP = this.tableDataP.concat(res.Message)
          } else {
            const arr = res.Message.filter(item => this.tableDataP.find(item1 => item.IDX !== item1.IDX))
            this.tableDataP = this.tableDataP.concat(...arr)
          }
          this.visiblePRODUCT = false
          return this.$message.success('导入成功')
        }
        return this.$message.success('数据导入失败')
      })
    },
    handleFileChangeFile(e) {
      console.log(e)
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        this.productFile = {
          FileData: e.target.result,
          FileType: file.name.split('.')[file.name.split('.').length - 1]
        }
      }
    },
    // 下载模板
    downTemplate() {
      window.open('${b2bstaticUrl}/WebUpload/download/采购申请导入模板.xls')
    },
    // 导入商品
    exportProduct() {
      this.visiblePRODUCT = true
    },
    chioceUser() {
      this.seletUserVisible = true
      this.checkeduserIdList = []
      // 人员
      this.getSelectUserQueryListOfDept()
      this.getRoledata()
      this.getdeptList()
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
        this.isDataCome = false
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // ================================================= 选择人员 begin  =================================================
    // ================================================= 选择人员 begin ==================================================
    getdeptList() {
      const _this = this
      $userRequest.GetDeptList().then(res => {
        if (res.RetCode === '0') {
          _this.selectUserDeptData = res.Message
          this.isDataCome = false
        }
      })
    },
    getRoledata() {
      const _this = this
      $userRequest.GetRoleList({ pageIndex: 1, pageSize: -1 }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.selectUserroleData = res.Message
          this.isDataCome = false
        }
      })
    },
    isReject(activeName, oldActiveName) {
      console.log(activeName, oldActiveName)
      if (activeName === 'user') {
        if (this.checkedDeptList.length > 0 || this.checkedRoleList.length > 0) {
          this.$message.warning('只能选择一个类型')
          return false
        }
      }
      if (activeName === 'dept') {
        if (this.checkeduserIdList.length > 0 || this.checkedRoleList.length > 0) {
          this.$message.warning('只能选择一个类型')
          return false
        }
      }
      if (activeName === 'role') {
        if (this.checkeduserIdList.length > 0 || this.checkedDeptList.length > 0) {
          this.$message.warning('只能选择一个类型')
          return false
        }
      }
    },
    selectUseractiveNamehandleClick(val) {
      console.log(val)
      // checkeduserIdList 人员
      //  checkedDeptList: [], // 选中的部门
      // checkedRoleList: [],  角色name="user" />
    },
    // 初始化选择人员数据
    oprenSelectContractUser() {
      const _this = this
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 选中人员
    checkeduser(ischecked, val, item, ii) {
      console.log(ischecked, val, item, ii)
      // if (ii && ischecked) {
      //   item.ischecked = true
      // }
      // 默认没选中
      if (ii && !ischecked && item.nodeType === 1 && item.IDX) {
        item.ischecked = true
      }
      if (ii && ischecked && item.nodeType === 1 && item.IDX) {
        item.ischecked = false
      }
      if (item.ischecked) {
        this.checkeduserIdList.push(item)
      }
      if (!item.ischecked) {
        this.checkeduserIdList.map((item1, index) => {
          if (item1.IDX === item.IDX) {
            this.checkeduserIdList.splice(index, 1)
          }
        })
      }
      console.log('this.checkeduserIdList', this.checkeduserIdList)
    },
    // 部门
    checkedDept(ischecked, val, item, ii) {
      console.log(ischecked, val, item, ii)
      // 默认没选中
      if (ii && !ischecked && item.IDX) {
        item.ischecked = true
      }
      if (ii && ischecked && item.IDX) {
        item.ischecked = false
      }
      if (item.ischecked) {
        this.checkedDeptList.push(item)
      }
      if (!item.ischecked) {
        this.checkedDeptList.map((item1, index) => {
          if (item1.IDX === item.IDX) {
            this.checkedDeptList.splice(index, 1)
          }
        })
      }
    },
    // 角色
    checkedRole(ischecked, item, ii) {
      console.log(item, ii)
      // 默认没选中
      if (ii && !ischecked && item.IDX) {
        item.ischecked = true
      }
      if (ii && ischecked && item.IDX) {
        this.$nextTick(() => {
          item.ischecked = false
        })
      }
      if (item.ischecked) {
        this.checkedRoleList.push(item)
      }
      if (!item.ischecked) {
        this.checkedRoleList.map((item1, index) => {
          if (item1.IDX === item.IDX) {
            this.checkedRoleList.splice(index, 1)
          }
        })
      }
    },
    cancelDraw() {
      this.seletUserVisible = false
      this.flag = true
      this.form.NickName = ''
      this.form.NoticeUser = ''
    },
    // 清空已选
    clearV() {
      this.form.NickName = ''
      this.form.NoticeUser = ''
      this.checkeduserIdList = []
    },
    // 确认选中人员
    doconnuser() {
      // form.NoticeUser
      console.log('this.checkeduserIdList', this.checkeduserIdList)
      this.seletUserVisible = false
      this.form.NickName = ''
      this.form.NoticeUser = ''
      this.checkeduserIdList.map(item => {
        this.form.NoticeUser += item.IDX + ','
        this.form.NickName += item.GroupName + ','
      })
    },
    // ================================================= 选择人员 end  =================================================
    // ================================================= 选择人员 end ==================================================
    searchList() {
      console.log(this.formSearch)
      if (this.formSearch.DateCA.length > 0) {
        this.formSearch.BeginDate = this.formSearch.DateCA[0]
        this.formSearch.EndDate = this.formSearch.DateCA[1]
      }
      this.setKeysData(this.formSearch, this.adSearchList) // 展示搜索条件
      this.getTableDataAsync()
      this.productShowList = false
      // this.formSearch = this.formSearchList
    },
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.GetCompanyNameQueryListJson1(query)
        }, 200)
      } else {
        this.GetCompanyNameQueryListJson1()
      }
    },
    // 关闭创建弹窗
    hideCreate() {
      this.$refs['ruleForm'].resetFields()
      this.visible = false
      this.isAddress = false
      this.addressList = []
      this.form.isKe = 0
      this.form.NickName = ''
      this.form.NoticeUser = ''
      this.form.TempBednrCompanyID = 0
    },
    // 客户接口
    GetCompanyNameQueryListJson1(CompanyName) {
      this.$api.GetCompanyNameQueryListJson1({
        companyType: 0,
        CompanyName: CompanyName || '',
        PageIndex: 1,
        pageSize: 100
      }).then(res => {
        console.log(res)
        this.clients = res.Message
      })
    },
    // 采购数量查询
    QueryPurchaseApplyCount() {
      this.$api.QueryPurchaseApplyCount().then(res => {
        this.ordderNumList = res.Message
      })
    },
    // 期效要求查询
    QueryExpiredDateList() {
      this.$api.QueryExpiredDateList().then(res => {
        this.DateList = res.Message
      })
    },
    // 入库仓库 QueryInStockList
    QueryInStockList() {
      this.$api.QueryInStockList().then(res => {
        this.StockList = res.Message
      })
    },
    // 退回或删除
    backOrDel(row) {
      console.log(row)
      // 退回
      if (row.OrderID) {
        this.$api.BackPurchaseApply({
          PurchaseBasciID: row.IDX
        }).then(res => {
          if (res.RetCode === '0') {
            this.refreshCurrentChange()
            return this.$message.success('退回成功')
          }
          return this.$message.error('退回失败')
        })
        return
      }
      this.$api.DeletePurchaseApply({
        PurchaseBasciID: row.IDX
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.refreshCurrentChange()
          return this.$message.success('删除成功')
        }
        return this.$message.error('删除失败')
      })
    },
    // 收货地址
    QueryDeliveryAddressList() {
      this.$api.QueryDeliveryAddressList({
        companyID: this.form.TempBednrCompanyID ? this.form.TempBednrCompanyID : 0
      }).then(res => {
        console.log(res)
        this.addressList = []
        this.addressList = res.Message
        this.isAddressLoading = false
      })
    },
    // 编辑地址
    edit(item) {
      for (const key in this.ruleForm1) {
        this.ruleForm1[key] = item[key]
      }
      console.log(this.ruleForm1)
      this.showSetAddress = true
      const province = this.ruleForm1.Province
      const cityq = this.ruleForm1.City
      const area = this.ruleForm1.Area
      this.ruleForm1.Province = TextToCode[province].code
      this.handleChange([this.ruleForm1.Province])
      console.log('cityq', cityq)
      console.log('province', province)
      this.ruleForm1.City = TextToCode[province][cityq].code
      this.Area = []
      const City2 = regionData.filter(item => item.value === this.ruleForm1.Province)
      const Area2 = City2[0].children.filter(item => item.value === this.ruleForm1.City)
      if (Area2[0].children) {
        Area2[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      this.ruleForm1.Area = TextToCode[province][cityq][area].code
    },
    // 收票地址
    QueryCollectTicketAddressList() {
      this.$api.QueryCollectTicketAddressList({
        companyID: this.form.BednrCompanyID ? this.form.BednrCompanyID : 0
      }).then(res => {
        console.log(res)
        this.addressList = []
        this.addressList = res.Message
        this.isAddressLoading = false
      })
    },
    // 删除地址
    DeleteUserAddressInfo(IDX) {
      this.$api.DeleteUserAddressInfo({
        IDX
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          if (this.ruleForm1.AddrType === 10) {
            this.QueryDeliveryAddressList()
          }
          if (this.ruleForm1.AddrType === 20) {
            this.QueryCollectTicketAddressList()
          }
          return this.$message.success('删除成功')
        }
        return this.$message.error('删除失败')
      })
    },
    // 通知处理人
    QueryNoticeUserList() {
      this.$api.QueryNoticeUserList().then(res => {
        console.log(res)
        this.UserList = res.Message
      })
    },
    async handleCurrentChange1(page) {
      //   this.catchAsync(
      // { Message, Total }
      const { Message, Total, RetCode } = await this.QueryMaterials(page, this.tablePageSize)
      // this.tableLoading = false
      if (RetCode === '0' && Message.length > 0) {
        this.tableTotalSize1 = Total
        this.tableDataProduct = Message
      }
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      if (!this.ruleForm1.IDX) {
        this.ruleForm1.Province = CodeToText[this.ruleForm1.Province[0]]
        this.ruleForm1.City = CodeToText[this.ruleForm1.City[0]]
        this.ruleForm1.Area = CodeToText[this.ruleForm1.Area[0]]
      }
      if (this.ruleForm1.IDX) {
        this.ruleForm1.Province = CodeToText[this.ruleForm1.Province]
        this.ruleForm1.City = CodeToText[this.ruleForm1.City]
        this.ruleForm1.Area = CodeToText[this.ruleForm1.Area]
      }
      this.ruleForm1.CompanyID = this.form.BednrCompanyID ? this.form.BednrCompanyID : 0
      if (this.form.isKe === 0) {
        this.ruleForm1.CompanyID = 0
      }
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.ruleForm1.TakeSelf ? this.ruleForm1.TakeSelf = 1 : this.ruleForm1.TakeSelf = 0
        // 保存地址
        this.$api.SaveUserAddress(this.ruleForm1).then(res => {
          console.log(res)
          this.showSetAddress = false
          if (res.RetCode === '0') {
            this.$refs[formName].resetFields()
            if (this.ruleForm1.AddrType === 10) {
              this.QueryDeliveryAddressList()
            }
            if (this.ruleForm1.AddrType === 20) {
              this.QueryCollectTicketAddressList()
            }
            this.ruleForm1.CompanyID = ''
            return this.$message.success('保存成功')
          }
          this.ruleForm1.CompanyID = ''
          return this.$message.error('保存失败')
        })
      })
    },
    handleChange(value) {
      console.log(value[0])
      this.City = []
      const City = []
      Object.assign(City, regionData.filter(item => item.value === value[0]))
      City[0].children.map(item => {
        this.City.push({
          label: item.label,
          value: item.value
        })
      })
      // this.ruleForm1.Province = CodeToText[value[0]]

      // console.log(this.City)
    },
    handleChange1(value) {
      this.Area = []
      const City = regionData.filter(item => item.value === this.ruleForm1.Province[0])
      console.log(City)
      const Area = City[0].children.filter(item => item.value === value[0])
      console.log(Area)
      if (Area[0].children) {
        Area[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      // this.ruleForm1.City = CodeToText[value[0]]
    },
    handleChange2(value) {
      // this.ruleForm1.Area = CodeToText[value[0]]
    },
    handleFileChange(e) {
      const _this = this
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        _this.$api.OtherInStockUploadAccessory({
          FileExtName: '.' + file.type.split('/')[1],
          Base64String: e.target.result
        }).then(res => {
          _this.ruleForm1.Filepath = res.Message
        })
      }
    },
    // 设置默认地址
    SetDefalutAddress(IDX) {
      console.log(this.ruleForm1)
      this.$api.SetDefalutAddress({ IDX }).then(res => {
        if (res.RetCode === '0') {
          if (this.ruleForm1.AddrType === 10) {
            this.QueryDeliveryAddressList()
          }
          if (this.ruleForm1.AddrType === 20) {
            this.QueryCollectTicketAddressList()
          }
          return this.$message.success('设置成功')
        }
        return this.$message.error('设置失败')
      })
    },
    checkboxInit(row, index) {
      if (row.disabled) {
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    // 添加产品
    async  QueryMaterials(page, pageSize) {
      this.formProduct.PageIndex = page || 1
      this.formProduct.PageSize = this.tablePageSize1
      const response = await this.$api.QueryMaterials(this.formProduct)
      this.tableDataProduct = response.Message
      this.tableTotalSize1 = response.Total
      this.tableDataP.map(item => {
        this.tableDataProduct.map(item1 => {
          if (item.IDX === item1.IDX) {
            this.$nextTick(() => {
              console.log('item', item)
              this.$refs.testTable.toggleRowSelection(item, true)
              this.$set(item1, 'disabled', true)
            })
          }
        })
      })
      console.log(response)
      return response
      // this.$api.QueryMaterials(this.formProduct).then(res => {
      //   this.tableDataProduct = res.Message
      //   if (this.tableDataP.length > 0) {
      //     this.tableDataP.map(item => {
      //       this.tableDataProduct.map(item1 => {
      //         if (item.IDX === item1.IDX) {
      //           this.$nextTick(() => {
      //             console.log('item', item)
      //             this.$refs.testTable.toggleRowSelection(item, true)
      //             this.$set(item1, 'disabled', true)
      //           })
      //         }
      //       })
      //     })
      //   }
      // })
    },
    // submitForm
    submitForm() {
      this.form.LotNoRequire ? this.form.LotNoRequire = 10 : this.form.LotNoRequire = ''
      if (this.form.isKe === 1) {
        this.form.IsDirectSending = 1
        this.form.IsTakeSelf = 0
      }
      if (this.form.isKe === 0) {
        this.form.IsDirectSending = 0
        this.form.IsTakeSelf = 0
        // this.form.BednrCompanyID = 0
      }
      if (this.form.isKe === 2) {
        this.form.IsDirectSending = 0
        this.form.IsTakeSelf = 1
      }
      this.form.DetailList = this.tableDataP
      if (this.form.StockCode === '') this.form.StockCode = 0
      if (this.form.ExpiredDate === '') this.form.ExpiredDate = 0
      if (this.form.DivisionDeptID === '') this.form.DivisionDeptID = 0

      // let str = ''
      // if (this.form.NoticeUser) {
      //   this.form.NoticeUser.map(item => {
      //     str += item + ','
      //   })
      // }
      // this.form.NoticeUser = str
      console.log(this.form)
      if (this.form.DetailList.length <= 0) return this.$message.warning('请选择你要采购的物料')
      const arr = this.form.DetailList.filter(item => !item.Num)
      if (arr.length > 0) return this.$message.warning('请检查物料数量')
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) return false
        this.isSubmit = true
        console.log(this.form)
        this.$api.CreatePurchaseApply(this.form).then(res => {
          console.log(res)
          if (res.RetCode === '0') {
            this.visible = false
            this.getTableDataAsync()
            this.$refs['ruleForm'].resetFields()
            this.form.NickName = ''
            this.form.NoticeUser = ''
            this.isSubmit = false
            return this.$message.success('提交成功')
          }
          this.$refs['ruleForm'].resetFields()
          this.form.NickName = ''
          this.form.NoticeUser = ''
          this.isSubmit = false
          return this.$message.error('提交失败' + res.RetMsg)
        })
      })
    },
    // 批量添加
    addGoodS() {
      if (!this.selectsP.length) return this.$message.info('请选择物料')
      this.tableDataP = this.tableDataP.concat(...this.selectsP)
      this.productShow = false
      this.visible1 = false
      this.selectsP = []
    },
    // 删除
    delProduct(index) {
      this.tableDataP.splice(index, 1)
    },
    // 单个添加
    addGoodOne(row) {
      this.tableDataP.push(row)
      this.productShow = false
      // this.visible = false
      this.visible1 = false
    },
    searchAdP() {
      this.formProduct.PageIndex = 1
      this.formProduct.PageSize = this.tablePageSize1
      this.$api.QueryMaterials(this.formProduct).then(res => {
        console.log('搜搜', res)
        this.tableDataProduct = res.Message
        if (this.tableDataP.length > 0) {
          this.tableDataP.map(item => {
            this.tableDataProduct.map(item1 => {
              if (item.IDX === item1.IDX) {
                this.$nextTick(() => {
                  console.log('item', item)
                  this.$refs.testTable.toggleRowSelection(item, true)
                  this.$set(item1, 'disabled', true)
                })
              }
            })
          })
        }
      })
    },
    selectAll(e) {
      console.log(e) // MaterialLotNo
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    selectAllP(e) {
      console.log(e) // MaterialLotNo
      this.selectsP = e
    },
    selectP(row) {
      this.selectsP = row
    },
    // 添加产品弹创
    addNewProducts() {
      this.visible1 = true
      this.QueryMaterials()
    },
    changeType(val) {
      this.QueryType = val
      this.getTableDataAsync()
    },
    isChangeAdress(value) {
      console.log(value)
      this.isAddress = false
      if (this.form.isKe !== 0) {
        this.form.address = ''
        this.form.card = ''
        this.form.SendID = ''
        this.form.CollectTicketSendID = ''
      }
      this.form.TempBednrCompanyID = value
      // BednrCompanyID
      this.$api.QueryDeptNameQueryListJson({
        DeptName: '',
        CompanyID: value
      }).then(res => {
        console.log(res)
        this.DeptNameQueryListJson = res.Message
      })
    },
    Adsearch() {
      this.isShow = true
    },
    searchAd() {
      this.isShow = false
    },
    // 选择默认
    slectAddressDefault(item, type) {
      if (type === 10) {
        this.form.SendID = item.IDX
        this.form.address = `
        ${item.Province ? item.Province : ''}
        ${item.City ? item.City : ''}
        ${item.Area ? item.Area : ''}
        ${item.Street ? item.Street : ''}
        ${item.Address ? item.Address : ''}
        ${item.ContactName ? item.ContactName : ''}
        ${item.ContactTel ? item.ContactTel : ''}
        `
        // this.form.address = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
      }
      if (type === 20) {
        this.form.CollectTicketSendID = item.IDX
        this.form.card = `
        ${item.Province ? item.Province : ''}
        ${item.City ? item.City : ''}
        ${item.Area ? item.Area : ''}
        ${item.Street ? item.Street : ''}
        ${item.Address ? item.Address : ''}
        ${item.ContactName ? item.ContactName : ''}
        ${item.ContactTel ? item.ContactTel : ''}
        `
        // this.form.card = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
      }
    },
    // 选择
    slectAddressInfo(item) {
      if (this.ruleForm1.AddrType === 10) {
        this.form.SendID = item.IDX
        this.form.address = `
        ${item.Province ? item.Province : ''}
        ${item.City ? item.City : ''}
        ${item.Area ? item.Area : ''}
        ${item.Street ? item.Street : ''}
        ${item.Address ? item.Address : ''}
        ${item.ContactName ? item.ContactName : ''}
        ${item.ContactTel ? item.ContactTel : ''}
        `
        // this.form.address = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
      }
      if (this.ruleForm1.AddrType === 20) {
        this.form.CollectTicketSendID = item.IDX
        this.form.card = `
        ${item.Province ? item.Province : ''}
        ${item.City ? item.City : ''}
        ${item.Area ? item.Area : ''}
        ${item.Street ? item.Street : ''}
        ${item.Address ? item.Address : ''}
        ${item.ContactName ? item.ContactName : ''}
        ${item.ContactTel ? item.ContactTel : ''}
        `
        // this.form.card = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
      }
    },
    // 选择地址
    selectAddress(type) {
      this.ruleForm1.AddrType = type
      if (this.form.isKe === 0) { this.form.TempBednrCompanyID = 0 }
      if (this.form.isKe === 1 && !this.form.TempBednrCompanyID) {
        if (!this.form.BednrCompanyID) return this.$message.info('请选择客户')
        this.form.TempBednrCompanyID = this.form.BednrCompanyID
      }
      if (!this.form.BednrCompanyID && this.form.isKe === 1) return this.$message.info('请选择客户')
      this.isAddress = true
      if (type === 10) {
        this.QueryDeliveryAddressList()
      }
      if (type === 20) {
        this.QueryCollectTicketAddressList()
      }
    },
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    testChange(row) {
      let Status = ''
      const arr = []
      row.status.forEach(item => {
        if (item === '未处理') {
          arr.push(0)
        }
        if (item === '已处理') {
          arr.push(1)
        }
        if (item === '部分处理') {
          arr.push(2)
        }
        if (item === '已退回') {
          arr.push(3)
        }
        if (item === '已删除') {
          arr.push(4)
        }
      })
      arr.forEach(item => {
        Status += `${item},`
      })
      this.formSearch.Status = Status
      this.getTableDataAsync()
    },
    changeGroup(type) {
      console.log(type)
      this.isAddress = false
      this.form.address = ''
      this.form.card = ''
      this.form.SendID = ''
      this.form.CollectTicketSendID = ''
      this.form.SendID = ''
      this.form.address = ''
    },
    // ischangeG(type) {
    //   console.log(type)
    //   if (type) { this.form.SendID = '' }
    // },
    async getTableDataAsync(page) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      this.formSearch.QueryType = this.QueryType
      const response = await this.$api.QueryPurchaseApplyList(this.formSearch)
      this.tableData = response.Message ? response.Message : []
      this.tableTotalSize = response.Total
      console.log(' this.tableData', this.tableData)
      return response
    },
    showCreateOrder() {
      this.visible = true
      this.tableDataP = []
      this.QueryExpiredDateList()
      this.QueryInStockList()
      // 客户
      this.GetCompanyNameQueryListJson1()
      this.QueryNoticeUserList()
    }
  }
}
export default Index
