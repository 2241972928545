<template>
  <div class="purchase-request">
    <div class="orderHeader flex j-between p-top-10 p-lr-30 TopTongji_line">
      <div class="hederleft flex j-between">
        <div :class="QueryType === 1 ? orderNumClassActive : orderNumClass" @click="changeType(1)">所有申请：<span class="headerFonts p-left-10">{{ ordderNumList.Total }}</span></div>
        <div :class="QueryType === 2 ? orderNumClassActive : orderNumClass" @click="changeType(2)">待处理的申请：<span class="headerFonts headerFontscolor p-left-10">{{ ordderNumList.PendingNum + ordderNumList.DealPartNum }}</span></div>
        <!-- <div :class="QueryType === 3 ? orderNumClassActive : orderNumClass" @click="changeType(3)">部分处理的申请：<span class="headerFonts headerFontscolor p-left-10">{{ ordderNumList.DealPartNum }}</span></div> -->
        <div :class="QueryType === 4 ? orderNumClassActive : orderNumClass" @click="changeType(4)">已处理的申请：<span class="headerFonts p-left-10">{{ ordderNumList.DealNum }}</span></div>
        <div :class="QueryType === 5 ? orderNumClassActive : orderNumClass" @click="changeType(5)">已退回/已删除的申请：<span class="headerFonts p-left-10">{{ ordderNumList.ReturnNum }}</span></div>
        <div :class="QueryType === 6 ? orderNumClassActive : orderNumClass" @click="changeType(6)">我处理的申请：<span class="headerFonts p-left-10">{{ ordderNumList.OwnDealNum }}</span></div>
      </div>
    </div>
    <div class="searchView flex a-center m-tb-10 j-between">
      <div class="flex m-left-20 SearchBox_30" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="可通过采购申请单号、销售订单号、终端名称、通知处理人、采购申请创建人搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="() => productShowList = !productShowList">
              <!--  @click="Adsearch" -->
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div v-if="$minCommon.checkMeau('cgsqadd', userBottons)" class="right m-left-10 m-right-20">
        <el-button class="ModelBttton-white-30" round type="primary" @click="showCreateOrder">创建采购申请</el-button>
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchList,'DateCA','BeginDate','EndDate')" />
    <div class="m-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        height="calc(100vh - 315px)"
        tooltip-effect="dark"
        show-header
        row-key="id"
        border
        width="100%"
        @select-all="selectAll"
        @select="select"
        @filter-change="testChange"
      >
        <el-table-column
          :filter-method="filterHandler"
          :filters="[{text: '未处理', value: '未处理'}, {text: '已处理', value: '已处理'},{text: '部分处理', value: '部分处理'}, {text: '已退回', value:'已退回'},{text: '已删除', value: '已删除'}]"
          prop="StatusShow"
          column-key="status"
          label="申请单状态"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.StatusShow }}
          </template>
        </el-table-column>
        <el-table-column prop="PRID" label="采购申请" show-overflow-tooltip width="130" />
        <el-table-column prop="OrderIDSourceShow" label="订单类型" show-overflow-tooltip />
        <el-table-column prop="OrderID" label="订单来源" show-overflow-tooltip width="100" />
        <el-table-column prop="NickName" label="采购申请人" show-overflow-tooltip width="100" />
        <el-table-column prop="WriteTime" label="申请时间" show-overflow-tooltip width="130">
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="ArrivalDateShow" label="期望到货时间" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            {{ scope.row.ArrivalDateShow }}
          </template>
        </el-table-column>
        <el-table-column prop="NoticeUserNickName" label="待处理人" show-overflow-tooltip />
        <el-table-column prop="OrderCustomer" label="订单客户" show-overflow-tooltip width="200" />
        <el-table-column prop="Remark" label="申请备注 " show-overflow-tooltip />
        <el-table-column fixed="right" prop="set" align="center" width="50" label="操作">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="!(scope.row.Status === 0 || scope.row.Status === 2) ? '' : 130"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton">
                  <el-link v-if="$minCommon.checkMeau('cgsqdeal', userBottons) && (scope.row.Status === 0 || scope.row.Status === 2)" :underline="false" @click="() => $router.push({name:'purchaseRequestDetail',params:{ IDX: scope.row.IDX} })"><i class="el-icon-view p-right-10" />采购申请处理</el-link>
                  <el-link v-if="$minCommon.checkMeau('cgsqview', userBottons) && !(scope.row.Status === 0 || scope.row.Status === 2)" :underline="false" @click="() => $router.push({name:'purchaseRequestDetail',params:{ IDX: scope.row.IDX} })"><i class="el-icon-view p-right-10" />查看</el-link>
                </div>
                <div v-if="scope.row.Status === 0" class="OperationButton">
                  <el-link v-if="$minCommon.checkMeau('cgsqreturndeal', userBottons)" :underline="false" @click="backOrDel(scope.row)"><i :class="scope.row.OrderID ? 'el-icon-d-arrow-left  p-right-10' : 'el-icon-delete p-right-10'" />{{ scope.row.OrderID ? '退回':'删除' }}采购申请</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch
      :form="formSearch"
      :ad-search-form="adSearchForm"
      :show="isShow"
      @cancel="(e) => isShow = e"
      @searchAd="searchAd"
    />
    <!-- 创建采购申请 start-->
    <el-dialog class="DepartmentBox Application" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="visible" width="800px!important" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">添加采购申请</span>
        <i class="el-icon-close Close" @click="hideCreate" />
      </div>
      <el-form ref="ruleForm" :model="form" :inline="true" label-width="120px">
        <div class="flex j-between p-top-20" style="width:100%">
          <el-form-item
            label="客户"
            prop="BednrCompanyID"
          >
            <el-select v-model="form.BednrCompanyID" clearable remote :loading="loading" :remote-method="remoteMethod" filterable size="mini" placeholder="请选择" @change="isChangeAdress">
              <el-option
                v-for="(item, index) in clients"
                :key="index"
                :label="item.text"
                :value="item.IDX"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="通知处理人" prop="NoticeUser">
            <el-input
              clearable
              :value="form.NickName"
              size="mini"
              placeholder="请选择通知处理人"
              class="input-with-select"
              @clear="clearV"
            >
              <el-button slot="append" icon="el-icon-search" @click="chioceUser" />
            </el-input>
            <!-- <el-select v-model="form.NoticeUser" filterable multiple size="mini" placeholder="请选择">
              <el-option
                v-for="(item, index) in UserList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select> -->
            <!-- <el-input v-model="form.NoticeUser" size="mini" placeholder="请选择通知人" class="input-with-select">
              <el-button slot="append" icon="el-icon-search" />
            </el-input> -->
          </el-form-item>
        </div>
        <div class="flex j-between" style="width:100%">
          <el-form-item label="期望到货日期" prop="ArrivalDate">
            <el-date-picker
              v-model="form.ArrivalDate"
              type="date"
              size="mini"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="效期要求" prop="ExpiredDate">
            <el-select v-model="form.ExpiredDate" placeholder="请选择" size="mini">
              <el-option
                v-for="item in DateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="flex j-between" style="width:100%">
          <el-form-item label="批号要求" class="p-right-20" prop="LotNoRequire">
            <el-checkbox v-model="LotNoRequire">同上次批号</el-checkbox>
          </el-form-item>
          <el-form-item label="是否直接送客户">
            <el-radio-group v-model="form.isKe" prop="isKe" @change="changeGroup">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
              <!-- <el-checkbox v-model="form.IsTakeSelf" :disabled="form.isKe" @change="ischangeG">自选</el-checkbox> -->
              <el-radio :label="2">自提</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="flex j-between" style="width:100%">
          <el-form-item label="入库仓位" prop="StockCode">
            <el-select v-model="form.StockCode" placeholder="请选择" size="mini">
              <el-option
                v-for="(item, index) in StockList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="科室" prop="DivisionDeptID">
            <el-select v-model="form.DivisionDeptID" placeholder="请选择" size="mini">
              <el-option
                v-for="(item, index) in DeptNameQueryListJson"
                :key="index"
                :label="item.GroupName"
                :value="item.IDX"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="flex j-between" style="width:100%">
          <el-form-item
            label="收货地址"
            class="flex"
            prop="address"
            :rules="[
              { required: form.isKe !== 2 , message: '收货地址不能为空'}]"
          >
            <div class="flex">
              <el-tooltip v-if="form.address" class="item" effect="dark" :content="form.address" placement="top-start">
                <span class="p-left-10 no-weight  el-1 min-border" style="flex:1">
                  {{ form.address }}
                </span>
              </el-tooltip>
              <!-- <el-input v-model="form.address" disabled size="mini" /> -->
              <div class="m-left-5" />
              <el-button :disabled="form.isKe == 2 ? true : false " size="mini" @click="selectAddress(10)">选择</el-button>
            </div>
          </el-form-item>
          <el-form-item
            label="收票地址"
            prop="card"
          >
            <div class="flex">
              <el-tooltip v-if="form.card" class="item" effect="dark" :content="form.card" placement="top-start">
                <span class="p-left-10 no-weight  el-1 min-border" style="flex:1">
                  {{ form.card }}
                </span>
              </el-tooltip>
              <!-- <el-input v-model="form.card" disabled size="mini" /> -->
              <div class="m-left-5" />
              <el-button size="mini" @click="selectAddress(20)">选择</el-button>
            </div>
          </el-form-item>

        </div>
        <el-form-item label="订单备注" prop="Remarks">
          <el-input v-model="form.Remarks" size="mini" />
        </el-form-item>
      </el-form>
      <!-- 地址 start-->
      <div v-if="isAddress" v-loading="isAddressLoading" class="address_view_set flex column">
        <div class="header min-border-bottom flex j-between a-center">
          <el-button class="ModelBttton1-white-24" style="margin-left:10px" @click="() => showSetAddress = true">新建地址</el-button>
          <el-button class="ModelBttton1-white-24" style="margin-right:10px" icon="el-icon-arrow-up" round @click="() => isAddress = false">收起</el-button>
        </div>
        <div v-if="addressList.length">
          <div v-for="(item,index) in addressList" :key="index" style="height:auto" class="item flex a-center p-tb-10 p-lr-10">
            <div style="flex:1;" class="el-2">
              {{ item.Province + item.City + item.Area + `${item.Street ? item.Street :''} `+ item.Address + item.ContactName + item.ContactTel }}
            </div>
            <div style="min-width:286px;margin-left:auto;" class="flex t-right">
              <el-button class="ModelBttton1-white-24" @click="edit(item)">编辑</el-button>
              <el-button class="ModelBttton1-white-24" @click="DeleteUserAddressInfo(item.IDX)">删除</el-button>
              <el-button v-if="!item.DefaultFlag" class="ModelBttton1-white-24" @click="SetDefalutAddress(item.IDX)">设为默认地址</el-button>
              <el-button v-if="item.SellStatus == 0" class="ModelBttton1-white-24" @click="slectAddressInfo(item)">选择</el-button>
              <el-button v-else class="ModelBttton1-white-24" size="mini" style="color:red;width:55px;padding:0">{{ item.SellStatusString }}</el-button>
            </div>
          </div>
        </div>
        <div v-else class="p-tb-30 center">暂无数据</div>
      </div>
      <!-- 地址 end-->
      <div class="addProduct flex j-end p-tb-10 t_line">
        <el-button size="mini" icon="el-icon-folder-add" @click="addNewProducts">添加产品</el-button>
        <el-button v-if="isDevelopment === 'development' || isDevelopment === 'test'" size="mini" icon="el-icon-folder-add" @click="exportProduct">直接导入产品</el-button>
      </div>
      <el-table
        :data="tableDataP"
        border
      >
        <el-table-column align="center" property="InvCode" label="物料编码" width="120" />
        <el-table-column align="center" property="ManuPartNum" label="原厂编号" width="auto" />
        <el-table-column align="center" property="InvName" label="物料名称" width="200" />
        <!--        <el-table-column align="center" property="Price" label="价格" width="auto" />-->
        <el-table-column align="center" property="Num" label="采购数量" width="100px">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.Num" style="width:60px" size="mini" :controls="false" :min="1" />
          </template>
        </el-table-column>
        <el-table-column align="center" property="Specification" label="物料规格" width="200" />
        <el-table-column align="center" property="BrandName" label="物料品牌" width="auto" />
        <el-table-column align="center" property="StockUnitN" label="单位" width="auto" />

        <el-table-column align="center" property="LotNum" label="批号" width="auto" />
        <el-table-column align="center" property="ExpiredDate" label="有效期" width="auto" />
        <el-table-column align="center" property="Remarks" label="备注" width="auto">
          <template slot-scope="scope">
            <el-input v-model="scope.row.Remarks" size="mini" />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="50">
          <template slot-scope="score">
            <el-button type="text" size="small" @click="delProduct(score.$index)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div id="btn" class="caozuo p-tb-20">
        <el-button @click="hideCreate">关闭</el-button>
        <el-button type="primary" :loading="isSubmit" @click="submitForm">提交</el-button>
      </div>
    </el-dialog>
    <!-- 创建采购申请 end-->
    <!-- 添加产品 start-->
    <el-dialog class="DepartmentBox" :visible.sync="visible1" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">添加商品</span>
        <i class="el-icon el-icon-close Close" @click="() => visible1 = false" />
      </div>
      <div class="search searchView flex j-around m-tb-20 SearchBox_30">
        <div class="flex m-right-10" style="flex: 1">
          <el-input
            v-model="formProduct.KeyWords"
            size="medium"
            placeholder="可通过物料号、物料名称、品牌、物料类型、原厂编号、di码搜索"
            class="rule-input-edit"
            @keyup.enter.native="searchAdP"
          >
            <template slot="prepend">
              <div class="pointer" @click="() => productShow = true">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template>
            <div
              slot="append"
              @click="searchAdP"
            >
              搜 索
            </div>
          </el-input>
        </div>
        <el-button class="ModelBttton-white-30" icon="el-icon-folder-add" round @click="addGoodS">批量添加</el-button>
      </div>
      <el-table
        ref="testTable"
        :data="tableDataProduct"
        @select-all="selectAllP"
        @select="selectP"
      >
        <el-table-column
          type="selection"
          width="45"
          max-height="500px"
          :selectable="checkboxInit"
        />
        <el-table-column align="center" property="InvCode" label="物料编码" show-overflow-tooltip />
        <el-table-column align="center" property="ManuPartNum" label="原厂编号" width="auto" />
        <el-table-column align="center" property="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" property="BrandName" label="物料品牌" show-overflow-tooltip />
        <el-table-column align="center" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="center" property="Specification" label="物料规格" show-overflow-tooltip />
        <el-table-column align="center" property="StockUnitN" label="物料单位" show-overflow-tooltip />
        <el-table-column align="center" property="a" fixed="right" label="操作" width="50">
          <template v-if="!scope.row.disabled" slot-scope="scope">
            <el-link type="primary" :underline="false" @click="addGoodOne(scope.row)">添加</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container m-bottom-20">
        <el-pagination
          :ref="paginationName1"
          :total="tableTotalSize1"
          :pager-count="pagerCount1"
          :page-size="tablePageSize1"
          :background="tableBackground1"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange1($event)"
        />
      </div>
    </el-dialog>
    <!-- 添加产品 end-->
    <!-- 新建地址 start -->
    <el-dialog class="DepartmentBox" width="410px!important" :visible.sync="showSetAddress" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">新建地址</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSetAddress = false" />
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="80px" class="demo-ruleForm">
        <el-form-item label="上门自提" prop="name">
          <el-checkbox v-model="ruleForm1.TakeSelf">备选项</el-checkbox>
        </el-form-item>
        <el-form-item label="省" prop="Province">
          <el-cascader
            v-model="ruleForm1.Province"
            size="mini"
            :options="Province"
            style="width:290px"
            @change="handleChange"
          />
          <!-- <el-input v-model="ruleForm1.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="市" prop="City">
          <el-cascader
            v-model="ruleForm1.City"
            size="mini"
            :options="City"
            style="width:290px"
            @change="handleChange1"
          />
          <!-- <el-input v-model="ruleForm1.City" size="mini" /> -->
        </el-form-item>
        <el-form-item label="区" prop="Area">
          <el-cascader
            v-model="ruleForm1.Area"
            size="mini"
            :options="Area"
            style="width:290px"
            @click="handleChange2"
          />
          <!-- <el-input v-model="ruleForm1.Area" size="mini" /> -->
        </el-form-item>
        <!-- <el-form-item label="街道" prop="Street">
          <el-input v-model="ruleForm1.Street" size="mini" />
        </el-form-item> -->
        <el-form-item label="地址" prop="Address">
          <el-input v-model="ruleForm1.Address" size="mini" />
        </el-form-item>
        <el-form-item label="收货部门" prop="DeptName">
          <el-input v-model="ruleForm1.DeptName" size="mini" />
        </el-form-item>
        <el-form-item label="联系人" prop="ContactName">
          <el-input v-model="ruleForm1.ContactName" size="mini" />
        </el-form-item>
        <el-form-item label="联系电话" prop="ContactTel">
          <el-input v-model="ruleForm1.ContactTel" size="mini" />
        </el-form-item>
        <el-form-item label="上传附件" prop="Filepath">
          <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange">
        </el-form-item>
        <el-form-item class="caozuo p-tb-20 t_line">
          <el-button @click="() => showSetAddress = false">取消</el-button>
          <el-button type="primary" @click="submitAddress('ruleForm1')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新建地址 end-->
    <!-- 添加产品 start -->
    <Adsearch :form="formProduct" :ad-search-form="adSearchFormP" :show="productShow" @cancel="(e) => productShow = e" @searchAd="searchAdP" />
    <!--添加产品 end  -->
    <!-- 高级搜索 start  -->
    <Adsearch :form="formSearch" :ad-search-form="adSearchList" :show="productShowList" @cancel="(e) => productShowList = e" @searchAd="searchList" />
    <!-- 高级搜索 end -->
    <!-- 选择通知人 start -->
    <el-dialog title="成员" class="DepartmentBox" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false" :show-close="false">
      <div v-loading="isDataCome" class="rolebody">
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan" @click="checkeduser(data.ischecked,data.value,data,true)">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-if="data.nodeType === 1 && data.IDX > 0" v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelDraw">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择通知人 end -->
    <!--    导入商品 start-->
    <el-dialog :close-on-press-escape="false" :close-on-click-modal="false" class="DepartmentBox" width="30%" :visible.sync="visiblePRODUCT" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">导入采购申请物料</span>
        <i class="el-icon el-icon-close Close" @click="() => visiblePRODUCT = false" />
      </div>
      <div class="flex j-start a-start column m-tb-20">
        <div>第一步：<el-link type="primary" @click="downTemplate">下载导入模板</el-link></div>
        <div class="m-tb-10 flex">
          第二步：<input ref="isRefFile" type="file" @change="handleFileChangeFile">
        </div>
        <div>第三步：<el-button size="mini" type="primary" @click="upExPORTfILE">导入</el-button>

        </div>
      </div>
    </el-dialog>

    <!--    导入商品 end-->
  </div>
</template>
<script>
import Index from '@/minxin/purchasing-management/purchase-request'
export default Index
</script>
<style>
/* .dialog_view{
    position: relative !important;
} */
@media  only screen and (max-width: 1400px) {
  .dialog_view {
    width: 90% !important;
    position: relative !important;
  }
}
@media only screen and ( max-width: 1000px) {
  .dialog_view {
    width: 90% !important;
  }
}
@media only screen and ( min-width: 1500px) {
  .dialog_view {
    width: 50% !important;
  }
}

.DepartmentBox .el-form-item__error {
  width: 100px;
   left: 5px
 }
</style>
<style scoped lang='scss'>
@import '@/style/order.scss';
@import "@/style/search.scss";
/* =======================选择人员 begin   =======================*/
/* =======================选择人员 begin   =======================*/

.deptnamespan {
  font-size: 12px;
  line-height: 20px;
  float: left;
}
.selectuserrole {
    width: 100%;
    height: 30px;
  }
.selectUserTree {
    margin-top: 15px;
}
.deptSpandiv {
  float: left;
  width: 20px;
  height: 20px;
  color: #409EFF;
  border: 1px #ddd solid;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
}
.deptnamespan {
  font-size: 12px;
  line-height: 20px;
  float: left;
}
.deptcountspan {
  font-size: 12px;
  line-height: 20px;
  float: right;
}
.rolebody {
  height: 280px;
  overflow: auto;
}
/* =======================选择人员 end   =======================*/
/* =======================选择人员 end   =======================*/
 .PageHeader1{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    color:   #006fda;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
 }
 .address_view_set{
   width:100%;
   height: auto;
   border-top: 1px solid #dcdfe6;
   overflow: hidden;
   .header{
     width:100%;
     height: 40px;
   }
   .item{
     width:100%;
     height: 40px;
     border-bottom: 1px #ddd dotted;
     box-sizing: border-box;
   }
 }
</style>
